import {
  Button, Divider, Row, Col, Form, Switch, Checkbox, Select, Input, Spin,
} from 'antd';

import moment from 'moment';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from '../../../actions';
import { BreadcrumbLevel } from '../../BreadcrumbLevel';
import { fullTagLabel, checkFeature } from '../../../utils';
import { DynamicFields } from '../../elements/DynamicFormFields';
import { CheckPurposePresetGroup } from './CheckPurposePresets';
import { withContentLayout } from '../../layout/Layout';
import { PurposeStatePresetGroup } from './PurposeStatePresets';
import { PurposeLocationPresetGroup } from './PurposeLocationPresets';


const DEFAULT_NPC_VALIDITY = '1095';
const DEFAULT_RE_REQUEST_PRIOR_EXPIRY = '42'; // six weeks
const DEFAULT_DUPLICATE_SUPPRESSION_WINDOW = '0';


const RequestPoliceCheckSettings = ({
  policeCheckPaperworkSigned, policeCheckRequestedTs, policeCheckRequestor, handleUpgradeRequest, policeCheckRequestorName, loading,
}) => {
  const italicH2 = { padding: '10px', fontStyle: 'italic', color: '#ccc' };
  return (
    <Spin spinning={loading}>
      <div style={{ textAlign: 'center' }}>
        { (!policeCheckPaperworkSigned) && <h2 style={italicH2}>Your subscription level does not currently allow <br />you to configure Police Check Settings</h2>}
        { ((!policeCheckPaperworkSigned && !policeCheckRequestor)) && <Button type="primary" size="large" onClick={ev => handleUpgradeRequest(ev)}>Request Upgrade</Button>}
        { policeCheckRequestedTs && policeCheckRequestor && (
        <h2 style={italicH2}>Upgrade requested on {moment(policeCheckRequestedTs).format('YYYY-MM-DD hh:mm:ss')} by {policeCheckRequestorName}</h2>
        )}
      </div>
    </Spin>
  );
};


@withContentLayout
class NpcSettingsPageComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showNpcSettings: JSON.parse(window.localStorage.getItem('npcSettingsToggle')) || false,
    };
  }

  async componentWillMount() {
    await this.props.fetchCurrentOrganization();
    if (this.props.organizationId) {
      this.props.fetchTags(this.props.organizationId, true);
      const { npcRequestor, paperworkSignedTs } = this.props.npcSettingsData && this.props.npcSettingsData.toJS() || {};
      if (npcRequestor && !paperworkSignedTs) await this.props.getUser(npcRequestor);
      if (paperworkSignedTs) {
        window.localStorage.setItem('npcSettingsToggle', true);
        this.setState({ showNpcSettings: true });
      } else {
        window.localStorage.setItem('npcSettingsToggle', false);
        this.setState({ showNpcSettings: false });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.organizationId !== prevProps.organizationId) {
      this.props.fetchCurrentOrganization();
      this.props.fetchTags(this.props.organizationId);
    }
  }

  handleUpgradeRequest = async ev => {
    ev.preventDefault();
    await this.props.patchPoliceCheckRequestForOrg(this.props.organizationId);
    await this.props.fetchCurrentOrganization();
    const { npcRequestor } = this.props.npcSettingsData.toJS();
    if (npcRequestor) await this.props.getUser(npcRequestor);
  }

  handleSubmit = ev => {
    ev.preventDefault();
    const { institutionsCredentials: inCreds, organizationId } = this.props;
    const institutionsCredentials = (inCreds && inCreds.toJSON()) || {};
    this.props.form.validateFields((errors, values) => {
      if (errors) return;
      delete values.validityOverridesList;
      if (values.validityOverrides) values.validityOverrides = values.validityOverrides.filter(Boolean);
      if (!values.personPay || !values.personPayTag) values.personPayTag = null;
      this.props.patchOrganization(organizationId, {
        institutionsCredentials: {
          ...institutionsCredentials,
          npc: values,
        },
      });
    });
  }

  render() {
    const {
      form, disabled, policeCheckRequestorName, patchOrganizationInProgress, fetchOrganizationInProgress,
    } = this.props;
    const npcSettingsData = this.props.npcSettingsData ? this.props.npcSettingsData.toJSON() : {};
    const { getFieldDecorator } = form;
    const personPay = form.getFieldValue('personPay');
    const validityOverrideEnabled = form.getFieldValue('validityOverrideEnabled');
    const automaticReRequestEnabled = form.getFieldValue('automaticReRequestEnabled');
    const tagItems = this.props.tagItems ? this.props.tagItems.toJS() : [];
    const applyableTagFilters = tagItems.filter(tag => {
      if (tag.editable) return true;
      return false;
    });
    applyableTagFilters.sort((left, right) => {
      if (left.label > right.label) return 1;
      if (left.label < right.label) return -1;
      return 0;
    });
    const fullTagLabelsWithIds = applyableTagFilters.map(tag => ({ id: tag.id, label: fullTagLabel(tag) }));
    const { paperworkSignedTs, npcRequestor, npcRequestedTs } = npcSettingsData;
    const prevPersonPayTag = npcSettingsData.personPayTag && fullTagLabelsWithIds.find(tag => tag.id === npcSettingsData.personPayTag);
    const prevPersonPayTagLabel = prevPersonPayTag && prevPersonPayTag.label;
    const { showNpcSettings } = this.state;
    return (
      <div>
        <BreadcrumbLevel text="Settings" />
        <BreadcrumbLevel text="NPC Requests" />
        <div >
          <p style={{ flexGrow: 1, paddingRight: '16px' }}>
                    Configure your Organization National Police Check settings
          </p>
        </div>
        <Divider />
        { !showNpcSettings && (
        <RequestPoliceCheckSettings
          policeCheckPaperworkSigned={paperworkSignedTs}
          policeCheckRequestedTs={npcRequestedTs}
          policeCheckRequestor={npcRequestor}
          policeCheckRequestorName = {policeCheckRequestorName}
          handleUpgradeRequest={ev => this.handleUpgradeRequest(ev)}
          loading={fetchOrganizationInProgress}
        />
        )}
        {showNpcSettings && (
        <div>
          <Spin spinning={fetchOrganizationInProgress}>
            <Form onSubmit = {this.handleSubmit}>
              <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 10 }} labelAlign='left' label="Industry">
                {getFieldDecorator('industry', {
                  initialValue: npcSettingsData.industry,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Industry is required',
                    },
                    { min: 4, message: 'Industry must be minimum 4 characters.' },
                  ],
                })(
                  <Input
                    placeholder='e.g IT/Building/Hospitality'
                    disabled={disabled}
                    size="default"
                  />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 10 }} style={{ display: 'none' }} labelAlign='left' label="Paperwork Signed">
                {getFieldDecorator('paperworkSignedTs', {
                  initialValue: npcSettingsData.paperworkSignedTs || null,
                })(
                  <Input
                    disabled={disabled}
                    size="default"
                  />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 10 }} style={{ display: 'none' }} labelAlign='left' label="NPC Requested">
                {getFieldDecorator('npcRequestedTs', {
                  initialValue: npcSettingsData.npcRequestedTs || null,
                })(
                  <Input
                    disabled={disabled}
                    size="default"
                  />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 10 }} style={{ display: 'none' }} labelAlign='left' label="NPC Requestor">
                {getFieldDecorator('npcRequestor', {
                  initialValue: npcSettingsData.npcRequestor || null,
                })(
                  <Input
                    disabled={disabled}
                    size="default"
                  />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 10 }} style={{ display: 'none' }} labelAlign='left' label="Client ID">
                {getFieldDecorator('clientId', {
                  initialValue: npcSettingsData.clientId || null,
                })(
                  <Input
                    disabled={disabled}
                    size="default"
                  />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 10 }} style={{ display: 'none' }} labelAlign='left' label="Client Email">
                {getFieldDecorator('clientEmail', {
                  initialValue: npcSettingsData.clientEmail || null,
                })(
                  <Input
                    disabled={disabled}
                    size="default"
                  />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 12 }} className="wrap-label" labelAlign = 'left' label="Automatically accept No Disclosable Court Outcome results as Green" >
                {getFieldDecorator('autoSuitableNdco', {
                  initialValue: npcSettingsData.autoSuitableNdco || false,
                  valuePropName: 'checked',
                })(
                  <Switch />,
                )
        }
              </Form.Item>
              { checkFeature('npcSettingsRetakeOutcome') && <Form.Item labelCol={{ span: 12 }} className="wrap-label" colon={false} labelAlign = 'left' label='Explicit Decisions on NPC Outcomes are not final and can be "retaken"' >
                {getFieldDecorator('retakeNpcOutcome', {
                  initialValue: npcSettingsData.retakeNpcOutcome || false,
                  valuePropName: 'checked',
                })(
                  <Switch />,
                )
        }
              </Form.Item>}
              <Form.Item labelCol={{ span: 12 }} wrapperCol={{ span: 8 }} className="wrap-label" labelAlign='left' label="Duplicate suppression: Window to reuse a completed NPC">
                {getFieldDecorator('duplicateSuppressionWindow', {
                  initialValue: npcSettingsData.duplicateSuppressionWindow || DEFAULT_DUPLICATE_SUPPRESSION_WINDOW,
                  rules: [
                    {
                      required: false,
                      whitespace: true,
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ],
                })(
                  <Input
                    placeholder='Reuse Existing Window'
                    disabled={disabled}
                    size="default"
                    addonAfter="days"
                  />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 12 }} wrapperCol={{ span: 10 }} className="wrap-label" labelAlign='left' label="Automatically re-request new checks">
                {getFieldDecorator('automaticReRequestEnabled', {
                  initialValue: npcSettingsData.automaticReRequestEnabled ?? true,
                  valuePropName: 'checked',
                })(
                  <Switch />,
                )
        }
              </Form.Item>
              <Form.Item labelCol={{ span: 10, offset: 2 }} wrapperCol={{ span: 8 }} className="wrap-label" labelAlign='left' style={automaticReRequestEnabled ? { display: 'block' } : { display: 'none' }} label="Re-request new check prior to expiry in days">
                {getFieldDecorator('reRequestPriorExpiry', {
                  initialValue: npcSettingsData.reRequestPriorExpiry || DEFAULT_RE_REQUEST_PRIOR_EXPIRY,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Default Re-request number is required',
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ],
                })(
                  <Input
                    placeholder='Re-request prior to expiry'
                    disabled={disabled}
                    size="default"
                    addonAfter="days before expiry"
                  />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 10, offset: 2 }} wrapperCol={{ span: 10 }} className="wrap-label" labelAlign='left' style={{ display: automaticReRequestEnabled ? 'block' : 'none' }} label="Automatically re-request expired items via bulk import">
                {getFieldDecorator('reRequestExpired', {
                  initialValue: npcSettingsData.reRequestExpired ?? true,
                  valuePropName: 'checked',
                })(
                  <Switch />,
                )
        }
              </Form.Item>
              <Row type="flex" justify="space-between">
                <Col span={10}>
                  <Form.Item >
                    {getFieldDecorator('organizationPay', {
                      initialValue: npcSettingsData.organizationPay || true,
                      valuePropName: 'checked',
                    })(
                      <Checkbox > Organization Pays for Police Checks </Checkbox>,
                    )
        }
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item >
                    {getFieldDecorator('personPay', {
                      initialValue: npcSettingsData.personPay || false,
                      valuePropName: 'checked',
                    })(
                      <Checkbox > Person Pays for Police Checks </Checkbox>,
                    )
        }
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item labelCol={{ span: 14 }} wrapperCol={{ span: 2 }} label="Tag when person pays">
                {getFieldDecorator('personPayTag', {
                  initialValue: prevPersonPayTagLabel || undefined,
                })(<Select
                  style={{ minWidth: 300 }}
                  showSearch
                  disabled = {!personPay}
                  optionFilterProp="children"
                  placeholder="select a Tag"
                  filterOption
                   >
                  {fullTagLabelsWithIds.map(tag => <Select.Option value={tag.id} key={tag.id}>{tag.label}</Select.Option>)}
                </Select>)}
              </Form.Item>
              <Form.Item labelCol={{ span: 12 }} wrapperCol={{ span: 10 }} className="wrap-label" labelAlign='left' label="Custom Presets for Check Purpose">
                {getFieldDecorator('checkPurposePresets', {
                  initialValue: npcSettingsData.checkPurposePresets || [],
                })(
                  <CheckPurposePresetGroup />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 12 }} wrapperCol={{ span: 10 }} className="wrap-label" labelAlign='left' label="Custom Presets for State">
                {getFieldDecorator('purposeStatePresets', {
                  initialValue: npcSettingsData.purposeStatePresets || [],
                })(
                  <PurposeStatePresetGroup />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 12 }} wrapperCol={{ span: 10 }} className="wrap-label" labelAlign='left' label="Custom Presets for Purpose Location">
                {getFieldDecorator('purposeLocationPresets', {
                  initialValue: npcSettingsData.purposeLocationPresets || [],
                })(
                  <PurposeLocationPresetGroup />,
                )}
              </Form.Item>
              <Form.Item labelCol={{ span: 12 }} wrapperCol={{ span: 8 }} className="wrap-label" labelAlign='left' label="Default validity period in days">
                {getFieldDecorator('defaultValidity', {
                  initialValue: npcSettingsData.defaultValidity || DEFAULT_NPC_VALIDITY,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: 'Default validity is required',
                      pattern: new RegExp(/^[0-9]+$/),
                    },
                  ],
                })(
                  <Input
                    placeholder='Validity Period'
                    disabled={disabled}
                    size="default"
                    addonAfter="days"
                  />,
                )}
              </Form.Item>
              <h3>Overrides for default validity period</h3>
              <p>
       Enabling this feature allows you to override the default validity period based on custom tags.
              </p>
              <Form.Item labelCol={{ span: 8 }} className="wrap-label" colon={false} label="Validity override enabled" required>
                {getFieldDecorator('validityOverrideEnabled', {
                  valuePropName: 'checked',
                  initialValue: npcSettingsData.validityOverrideEnabled || false,
                })(
                  <Switch />,
                )
        }
              </Form.Item>
              { validityOverrideEnabled
       && <DynamicFields
         {...form}
         name="validityOverrides"
         customValues={npcSettingsData.validityOverrides}
         fields={[
           {
             name: 'tag',
             validation: {
               rules: [{
                 message: 'Cannot leave blank', required: true,
               }],
               validateTrigger: 'onSubmit',
             },
             field: () => (
               <Select
                 style={{ width: 300 }}
                 showSearch
                 optionFilterProp="children"
                 placeholder="select a Tag"
                 filterOption
               >
                 {fullTagLabelsWithIds.map(tag => <Select.Option value={tag.id} key={tag.id}>{tag.label}</Select.Option>)}
               </Select>
             ),
           },
           {
             name: 'validity',
             validation: {
               rules: [{
                 message: 'validity period is required', required: true, pattern: new RegExp(/^[0-9]+$/),
               }],
               validateTrigger: 'onSubmit',
             },
             field: () => <Input style={{ width: 300 }} placeholder={'Validity in days'} />,
           },
         ]}
          />}
              <Row justify="end" type="flex">
                <Col>
                  <Button
                    style={{ marginTop: '16px' }}
                    type="primary"
                    htmlType="submit"
                    loading={patchOrganizationInProgress}
                    size="large"
                  >Save</Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
        )}
      </div>
    );
  }
}

export const NpcSettingsPageForm = Form.create()(NpcSettingsPageComponent);

export const NpcSettingsPage = withRouter(connect(
  state => ({
    organizationId: state.doc.getIn(['authMr', 'organizationId'], null),
    organizationData: state.doc.getIn(['authMr', 'constituent', 'organization'], null),
    tagItems: state.doc.getIn(['tagsMr', 'items'], null),
    patchOrganizationInProgress: state.doc.getIn(['http', 'patchOrganization', 'inProgress'], false),
    patchOrganizationFailed: state.doc.getIn(['http', 'patchOrganization', 'failed'], false),
    patchOrganizationSuccess: state.doc.getIn(['http', 'patchOrganization', 'success'], false),
    institutionsCredentials: state.doc.getIn(['authMr', 'constituent', 'organization', 'institutionsCredentials'], null),
    policeCheckRequestForOrgInProgress: state.doc.getIn(['http', 'patchPoliceCheckRequestForOrg', 'inProgress'], true),
    policeCheckRequestForOrgFailed: state.doc.getIn(['http', 'patchPoliceCheckRequestForOrg', 'failed'], true),
    fetchOrganizationSuccess: state.doc.getIn(['http', 'fetchCurrentOrganization', 'success'], false),
    fetchOrganizationInProgress: state.doc.getIn(['http', 'fetchCurrentOrganization', 'inProgress'], false),
    fetchOrganizationFailed: state.doc.getIn(['http', 'fetchCurrentOrganization', 'failed'], false),
    npcSettingsData: state.doc.getIn(['http', 'fetchCurrentOrganization', 'data', 'institutionsCredentials', 'npc'], null),
    policeCheckRequestorName: state.doc.getIn(['http', 'getUser', 'data', 'constituent', 'fullName'], null),
  }),
  actions,
)(NpcSettingsPageForm));
