import { Form, Input, Select } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { API_DATE_FS, NON_ALPHA_NUM_RE } from './shared';

import getDateOfBirthField from '../../fields/DateOfBirthField';
import * as actions from '../../../actions';


class VevoFormItemsComponent extends PureComponent {
  static slug = 'vevo'

  static placeholder = 'ABA9875413'

  static identLabel = 'Passport Number'

  static countryLabel = 'Country Of Issue'

  static passportExpiryLabel = 'Passport Expiry'

  constructor(props) {
    super(props);
    this.state = {
      countryList: {},
    };
  }

  fetchCountryList = async () => {
    try {
      const response = await this.props.fetchVevoCountries();
      this.setState({ countryList: response.data });
    } catch (error) {
      console.error('Error fetching country list:', error);
    }
  };

  componentDidMount() {
    this.fetchCountryList();
  }

  static getSubmissionData(checkData, constituent) {
    return {
      identifier: checkData.identifier,
      country_of_issue: checkData.country_of_issue.toLowerCase(),
      birth_date: (constituent && constituent.get('birthDate')) || (checkData && checkData.birth_date.format(API_DATE_FS)),
    };
  }

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.replace(NON_ALPHA_NUM_RE, '');
  }

  static disabledDate(current) {
    return current && current.valueOf() < Date.now();
  }

  render() {
    const { form, disabled, rules, constituent } = this.props;
    const { getFieldDecorator } = form;

    const {
      slug, identLabel, placeholder, countryLabel,
      regNumberGetValueFromEvent,
    } = this.constructor;
    const { countryList } = this.state;

    const birthDate = constituent && constituent.get('birthDate');

    return (<div>
      <Form.Item label={ identLabel }>
        { getFieldDecorator(`${slug}.identifier`, {
          getValueFromEvent: regNumberGetValueFromEvent,
          rules: [
            {
              required: true,
              whitespace: true,
              message: `${identLabel} is required`,
            },
            ...(rules || []),
          ],
        })(
          <Input
            placeholder={ placeholder }
            disabled={ disabled }
          />,
        )
        }
      </Form.Item>
      <Form.Item label={ countryLabel }>
        { getFieldDecorator(`${slug}.country_of_issue`, {
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Country of issue is required',
            },
            ...(rules || []),
          ],
        })(
          <Select
            showSearch
            placeholder= 'Australia'
            disabled={ disabled }
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
          >
            {
            Object.keys(countryList).map(value => <Select.Option key={countryList[value]} value={value}>{value}</Select.Option>)
          }
          </Select>,
        )
        }
      </Form.Item>
      <Form.Item label="Date of Birth">
        {birthDate || (getFieldDecorator(`${slug}.birth_date`, {
          rules: [
            {
              required: true,
              message: 'Date of Birth is required',
            },
            ...(rules || []),
          ],
        }))(getDateOfBirthField({ initialValue: birthDate, yearsToRestrict: 18, disabled: false }))
        }
      </Form.Item>
    </div>);
  }
}

export const VevoFormItems = connect(
  state => ({
    fetchVevoCountriesInProgress: state.doc.getIn(['http', 'fetchVevoCountries', 'inProgress'], false),
    fetchVevoCountriesFailed: state.doc.getIn(['http', 'fetchVevoCountries', 'failed'], false),
    vevoCountries: state.doc.getIn(['http', 'fetchVevoCountries', 'data'], false),
  }),
  actions,
)(VevoFormItemsComponent);
