import React, { useState, useEffect } from 'react';
import {
  Button, Col, Form, Input, Modal, Row,
} from 'antd';


const TwoFactorModalComponent = ({
  title, description, visible, handleCancel, handleSubmit, form, error, initiateLinkage, hasStarted, otpSubmitted, updates,
}) => {
  const [isLinkageDisabled, SetIsLinkageDisabled] = useState(false);

  useEffect(() => {
    if (hasStarted) {
      SetIsLinkageDisabled(true);
    } else {
      SetIsLinkageDisabled(false);
    }
  }, [hasStarted]);

  const handleKeyUp = evt => {
    if (evt.key === 'Enter') handleSubmit(form);
  };

  const handleStartLinkageClick = () => {
    initiateLinkage();
    SetIsLinkageDisabled(true);
  };

  const otpComponent = (
    <>
      {error === null
        ? (<Form layout="vertical">
          <p>{description}</p>
          <Form.Item label="One-Time Password:">
            {form.getFieldDecorator('twoFactorCode', {
              rules: [{ required: true, message: 'Please enter one-time password, sent to you via SMS.' }],
            })(<Input onKeyUp={handleKeyUp} />)}
          </Form.Item>
        </Form>
        ) : <p style={{ color: 'red' }}>{error}</p>
      }
    </>
  );

  const startLinkageComponent = (
    <>
      {error === null
        ? (
          <>
            <p>Would you like to start {title}?</p>
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }} span={24}>
                <Button onClick={handleStartLinkageClick} disabled={isLinkageDisabled}>Start Linkage Process</Button>
              </Col>
            </Row>
          </>
        ) : <p style={{ color: 'red' }}>{error}</p>
      }
    </>
  );

  const isLinkingComponent = (
    <>
      <p>Linkage process is currently running</p>
      <p>{updates}</p>
    </>
  );

  let linkageComponent = null;
  if (hasStarted && !otpSubmitted) {
    linkageComponent = otpComponent;
  } else if (hasStarted && otpSubmitted) {
    linkageComponent = isLinkingComponent;
  } else {
    linkageComponent = startLinkageComponent;
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={() => handleSubmit(form)}
      onCancel={handleCancel}
      style={{ minWidth: 400 }}
      okButtonProps={{ style: { display: hasStarted && !otpSubmitted ? '' : 'none' }, disabled: !form.getFieldValue('twoFactorCode') }}
      okText="Submit"
    >
      {linkageComponent}
    </Modal>
  );
};

export default Form.create()(TwoFactorModalComponent);
